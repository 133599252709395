var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.tabs
    ? _c(
        "b-card-group",
        { staticClass: "mb-5" },
        _vm._l(_vm.tabs, function (value, name, index) {
          return _c("card-widget", {
            key: index,
            attrs: {
              id: name,
              to: _vm.makeRoute(name),
              title: _vm.$t(`corrective.tabs.${name}`),
              icon: _vm.activeTabs[name],
              value: String(value.total),
              active: _vm.tabActive(name),
            },
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }