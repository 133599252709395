<template>
  <b-modal
    id="modalCorrective"
    ref="modalCorrective"
    size="lg"
    :title="modalTitle || $t('corrective.titles.createScheduleModal')"
    no-close-on-backdrop
    no-close-on-esc
    @show="__loadData"
    @close="_sa_cancel"
  >
    <b-form>
      <b-container>
        <b-form-row>
          <b-form-group
            label="Status"
            label-for="status"
            class="col-sm-12 col-lg-3"
          >
            <b-form-select
              id="status"
              v-model="ObjectrCorrective.status"
              :disabled="false"
              :options="getStatusState"
              @change="_selectNewStatus(ObjectrCorrective.status)"
            />
          </b-form-group>
          <b-form-group
            label="Placa do carro"
            label-for="license_plate"
            class="col-sm-12 col-lg-3"
            :class="{'group-disabled': getFormState.car}"
          >
            <b-form-select
              id="license_plate"
              v-model="ObjectrCorrective.car"
              :options="carList"
              :disabled="getFormState.car"
              :class="{'empty-warning': ObjectrCorrectiveValidation.car}"
            />
          </b-form-group>
          <b-form-group
            :label="$t('corrective.labels.origin')"
            label-for="origin"
            class="col-sm-12 col-lg-6"
            :class="{'group-disabled': getFormState.origin}"
          >
            <b-form-select
              id="origin"
              v-model="ObjectrCorrective.origin"
              :disabled="getFormState.origin"
              :options="originList"
              :class="{'empty-warning': ObjectrCorrectiveValidation.origin}"
            />
          </b-form-group>
        </b-form-row>

        <b-form-row>
          <b-form-group
            :label="$t('corrective.labels.corrective_problems_list')"
            label-for="corrective_problems_list"
            class="col-md-12 col-sm-12"
            :class="{'group-disabled': getFormState.reported_issues, 'empty-warning': ObjectrCorrectiveValidation.reported_issues}"
          >
            <b-form-checkbox-group
              id="corrective_problems_list"
              v-model="ObjectrCorrective.reported_issues"
              :options="problemsList"
              :disabled="getFormState.reported_issues"
              class="problems-list"
              stacked
            />
          </b-form-group>
        </b-form-row>

        <b-form-row>
          <!-- Oficina --- Data de Agendamento -->
          <b-form-group
            :label="$t('corrective.labels.garage')"
            label-for="garage"
            class="col-sm-12 col-lg-6"
            :class="{'group-disabled': getFormState.garage}"
          >
            <b-form-select
              id="mechanic_garage"
              v-model="ObjectrCorrective.garage"
              :options="garageList"
              :disabled="getFormState.garage"
              :class="{'empty-warning': ObjectrCorrectiveValidation.garage}"
            />
          </b-form-group>

          <b-form-group
            :label="$t('corrective.labels.schedule_date')"
            label-for="schedule_date"
            class="col-sm-12 col-lg-3"
            :class="{'group-disabled': getFormState.schedule_date}"
          >
            <date-picker
              id="schedule_date"
              v-model="ObjectrCorrective.schedule_date"
              :not-before="$moment().format('YYYY-MM-DD')"
              :hide="getFormState.schedule_date"
              :class="{'empty-warning': ObjectrCorrectiveValidation.schedule_date}"
            />
          </b-form-group>
        </b-form-row>

        <b-form-row>
          <!-- Último motorista --- Agendamento -->
          <b-form-group
            :label="$t('corrective.labels.driver')"
            label-for="driver"
            class="col-sm-12 col-lg-6"
          >
            <b-form-input
              id="driver"
              v-model="ObjectrCorrective.last_driver.name"
              :disabled="true"
            />
          </b-form-group>
        </b-form-row>

        <b-row class="divider" />

        <b-form-row>
          <b-form-group
            :label="$t('corrective.labels.garage_arrival_date')"
            label-for="garage_arrival_date"
            class="col-sm-12 col-lg-4"
            :class="{'group-disabled': getFormState.garage_arrival_date}"
          >
            <date-picker
              id="garage_arrival_date"
              v-model="ObjectrCorrective.garage_arrival_date"
              :not-after="$moment().add(1, 'day').format('YYYY-MM-DD')"
              :hide="getFormState.garage_arrival_date"
              :class="{'empty-warning': ObjectrCorrectiveValidation.garage_arrival_date}"
            />
          </b-form-group>

          <b-form-group
            :label="$t('corrective.labels.estimate_cost_sent_date')"
            label-for="estimate_cost_sent_date"
            class="col-sm-12 col-lg-4"
            :class="{'group-disabled': getFormState.estimate_cost_sent_date}"
          >
            <date-picker
              id="estimate_cost_sent_date"
              v-model="ObjectrCorrective.estimate_cost_sent_date"
              :not-after="$moment().add(1, 'day').format('YYYY-MM-DD')"
              :hide="getFormState.estimate_cost_sent_date"
              :class="{'empty-warning': ObjectrCorrectiveValidation.estimate_cost_sent_date}"
            />
          </b-form-group>

          <b-form-group
            :label="$t('corrective.labels.finish_estimate_date')"
            label-for="finish_estimate_date"
            class="col-sm-12 col-lg-4"
            :class="{'group-disabled': getFormState.finish_estimate_date}"
          >
            <date-picker
              id="finish_estimate_date"
              v-model="ObjectrCorrective.finish_estimate_date"
              :not-before="this.$moment().format('YYYY-MM-DD')"
              :hide="getFormState.finish_estimate_date"
              :class="{'empty-warning': ObjectrCorrectiveValidation.finish_estimate_date}"
            />
          </b-form-group>
        </b-form-row>

        <b-form-row>
          <b-form-group
            :label="$t('corrective.labels.finish_date')"
            label-for="finish_date"
            class="col-sm-12 col-lg-6"
            :class="{'group-disabled': getFormState.finish_date}"
          >
            <date-picker
              id="finish_date"
              v-model="ObjectrCorrective.finish_date"
              :not-after="$moment().add(1, 'day').format('YYYY-MM-DD')"
              :hide="getFormState.finish_date"
              :class="{'empty-warning': ObjectrCorrectiveValidation.finish_date}"
            />
          </b-form-group>

          <b-form-group
            :label="$t('corrective.labels.delivery_date')"
            label-for="delivery_date"
            class="col-sm-12 col-lg-6"
            :class="{'group-disabled': getFormState.delivery_date}"
          >
            <date-picker
              id="delivery_date"
              v-model="ObjectrCorrective.delivery_date"
              :not-after="$moment().add(1, 'day').format('YYYY-MM-DD')"
              :hide="getFormState.delivery_date"
              :class="{'empty-warning': ObjectrCorrectiveValidation.delivery_date}"
            />
          </b-form-group>
        </b-form-row>

        <b-form-row>
          <b-form-group
            :label="$t('corrective.labels.is_driver_accountable')"
            :class="{'group-disabled': getFormState.is_driver_accountable}"
          >
            <b-form-radio-group
              id="is_driver_accountable"
              v-model="ObjectrCorrective.is_driver_accountable"
              name="radio-sub-component"
              :disabled="getFormState.is_driver_accountable"
              :class="{'empty-warning': ObjectrCorrectiveValidation.is_driver_accountable}"
            >
              <b-form-radio value="true">
                Sim
              </b-form-radio>
              <b-form-radio value="false">
                Não
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>

          <mask-input
            id="driver_accountable_value"
            v-model="ObjectrCorrective.driver_accountable_value"
            :disabled="getFormState.driver_accountable_value"
            :mask="['#####,##', '####,##', '###,##', '##,##', '#,##']"
            :mask-label="$t('corrective.labels.driver_accountable_value')"
            :prepend="$t('payments.labels.currency')"
            class="col-sm-12 col-lg-4"
            :class="{'group-disabled': getFormState.driver_accountable_value, 'empty-warning': ObjectrCorrectiveValidation.driver_accountable_value}"
          />

          <b-form-group
            :label="$t('corrective.labels.car_receiver')"
            label-for="car_receiver"
            class="col-sm-12 col-lg-4"
            :class="{'group-disabled': getFormState.car_receiver}"
          >
            <b-form-select
              id="car_receiver"
              v-model="ObjectrCorrective.car_receiver"
              :options="carReceiverList"
              :disabled="getFormState.car_receiver"
              :class="{'empty-warning': ObjectrCorrectiveValidation.car_receiver}"
            />
          </b-form-group>
        </b-form-row>

        <b-form-row>
          <mask-input
            id="labor_cost"
            v-model="ObjectrCorrective.labor_cost"
            :disabled="getFormState.labor_cost"
            :mask="['#####,##', '####,##', '###,##', '##,##', '#,##']"
            :mask-label="$t('corrective.labels.labor_cost')"
            :prepend="$t('payments.labels.currency')"
            class="col-sm-12 col-lg-6"
            :class="{'group-disabled': getFormState.labor_cost, 'empty-warning': ObjectrCorrectiveValidation.labor_cost}"
          />

          <mask-input
            id="parts_cost"
            v-model="ObjectrCorrective.parts_cost"
            :disabled="getFormState.parts_cost"
            :mask="['#####,##', '####,##', '###,##', '##,##', '#,##']"
            :mask-label="$t('corrective.labels.parts_cost')"
            :prepend="$t('payments.labels.currency')"
            class="col-sm-12 col-lg-6"
            :class="{'group-disabled': getFormState.parts_cost, 'empty-warning': ObjectrCorrectiveValidation.parts_cost}"
          />
        </b-form-row>
      </b-container>
    </b-form>
    <template v-slot:modal-footer>
      <b-button
        variant="light"
        @click="_sa_cancel"
      >
        {{ $t('corrective.buttons.cancel') }}
      </b-button>
      <b-button
        variant="dark"
        :disabled="disableOkButton"
        @click="__sendMutation"
      >
        {{ $t('corrective.buttons.createNewSchedule') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
  import { makeQuery, makeMutation } from "@graphql/middleware";
  import { mapActions, mapGetters } from 'vuex';
  import CANCEL from '@graphql/corrective/mutations/cancel.gql';
  import CREATE from '@graphql/corrective/mutations/create.gql';
  import DONE from '@graphql/corrective/mutations/done.gql';
  import IN_REPAIR from '@graphql/corrective/mutations/in-repair.gql';
  import PENDING_BUDGET from '@graphql/corrective/mutations/pending-budget.gql';
  import SCHEDULED from '@graphql/corrective/mutations/scheduled.gql';
  import READY_FOR_PICKUP from '@graphql/corrective/mutations/ready-for-pickup.gql';
  import GARAGES from '@graphql/garages/queries/list_for_maintenance.gql';
  import CARS from '@graphql/car/queries/list-all-plates.gql'
  import { ORIGIN_LIST, PROBLEMS_LIST } from "@enums/corrective";

  export default {
    name: 'ModalCorrective',
    data() {
      return {
        ObjectrCorrective: {
          car: '',
          origin: '',
          status: null,
          reported_issues: [],
          garage: '',
          garage_arrival_date: '',
          driver: '',
          schedule_date: '',
          delivery_date: '',
          estimate_cost_sent_date: '',
          finish_estimate_date: '',
          finish_date: '',
          is_driver_accountable: false,
          driver_accountable_value: '',
          car_receiver: '',
          labor_cost: '',
          parts_cost: '',
          last_driver: {
            name: ''
          }
        },
        ObjectrCorrectiveValidation: {
          car: false,
          origin: false,
          status: false,
          reported_issues: false,
          garage: false,
          garage_arrival_date: false,
          driver: false,
          schedule_date: false,
          delivery_date: false,
          estimate_cost_sent_date: false,
          finish_estimate_date: false,
          finish_date: false,
          is_driver_accountable: false,
          driver_accountable_value: false,
          car_receiver: false,
          labor_cost: false,
          parts_cost: false,
          last_driver: {
            name: false
          }
        },
        carList: [],
        garageList: [],
        originList: ORIGIN_LIST,
        problemsList: PROBLEMS_LIST,
        disableOkButton: true,
        enableExitAlert: false,
        modalTitle: this.$t('corrective.modals.default_modalTitle'),
        cancelButton: this.$t('corrective.buttons.cancel'),
        cancelAlert: this.$t('corrective.modals.cancelAlert'),
        confirmButton: 'OK',
        confirmAlert: this.$t('corrective.modals.default_confirmAlert'),
        carReceiverList: [
          { value: null, text: 'Selecione', disabled: true },
          { value: 'DRIVER', text: this.$t('corrective.enums.car_receiver_driver') },
          { value: 'CHAUFFEUR', text: this.$t('corrective.enums.car_receiver_chauffeur') },
        ]
      }
    },
    computed: {
      ...mapGetters({
        getCurrentId: 'corrective/g_currentId',
        getCorrective: 'corrective/g_corrective',
        getModalState: 'corrective/g_modalState',
        getStatusState: 'corrective/g_statusState',
        getStatusStateSelected: 'corrective/g_statusStateSelected',
        getFormState: 'corrective/g_formState'
      }),
    },
    mounted() {
      makeQuery(
        CARS, {}
      )
      .then((result) => {
        this.carList.push({
          value: null,
          text: 'Selecione',
          disabled: true
        });
        result.data.cars.items.forEach((car) => {
          this.carList.push({
            value: car.id,
            text: car.license_plate
          });
        })
      });

      makeQuery(
        GARAGES, {}
      )
      .then((result) => {
        const items = result.data.Maintenance.garages.items;

        this.garageList.push({
          value: null,
          text: 'Selecione',
          disabled: true
        });

        items.filter(i => i.status === "ACTIVE")
        .forEach((garage) => {
          this.garageList.push({
            value: garage.id,
            text: garage.name
          });
        })
      });

    },
    methods: {
      ...mapActions({
        setCorrective: 'corrective/a_corrective',
        setModalState: 'corrective/a_modalState',
        setStatusState: 'corrective/a_statusState'
      }),
      _makeMutationsCase() {
        const errorMutation = {
          type: 'error',
          title: 'Erro ao executar operação, refaça o processo!',
          showConfirmButton: true,
          confirmButtonText: 'Ok'
        };
        let mutateFile;
        let mutateVariables;
        if(this.getModalState === 'INITIAL' && this.getStatusStateSelected === 'PENDING_BUDGET') {
          mutateFile = CREATE;
          mutateVariables = {
            car: this.getCorrective.car,
            origin: this.getCorrective.origin,
            reported_issues: this.getCorrective.reported_issues,
            garage: this.getCorrective.garage,
            garage_arrival_date: this.getCorrective.garage_arrival_date,
            is_driver_accountable: this.getCorrective.is_driver_accountable === 'true',
            driver_accountable_value: parseInt(this.getCorrective.driver_accountable_value),
            car_receiver: this.getCorrective.car_receiver,
            finish_estimate_date: this.getCorrective.finish_estimate_date,
            labor_cost: parseInt(this.getCorrective.labor_cost),
            parts_cost: parseInt(this.getCorrective.parts_cost)
          }
        }
        if(this.getModalState === 'SCHEDULED' && this.getStatusStateSelected === 'PENDING_BUDGET') {
         mutateFile = PENDING_BUDGET;
         mutateVariables = {
            maintenance_id: this.getCurrentId,
            garage_arrival_date: this.getCorrective.garage_arrival_date,
            reported_issues: this.getCorrective.reported_issues,
            is_driver_accountable: this.getCorrective.is_driver_accountable === 'true',
            driver_accountable_value: parseInt(this.getCorrective.driver_accountable_value),
            car_receiver: this.getCorrective.car_receiver,
            finish_estimate_date: this.getCorrective.finish_estimate_date,
            labor_cost: parseInt(this.getCorrective.labor_cost),
            parts_cost: parseInt(this.getCorrective.parts_cost)
          }
        }
        if(this.getStatusStateSelected === 'SCHEDULED'){
         mutateFile = SCHEDULED;
         mutateVariables = {
            car: this.getCorrective.car,
            origin: this.getCorrective.origin,
            reported_issues: this.getCorrective.reported_issues,
            garage: this.getCorrective.garage,
            schedule_date: this.getCorrective.schedule_date
          }
        }
        if(this.getStatusStateSelected === 'IN_REPAIR'){
          mutateFile = IN_REPAIR;
          mutateVariables = {
            maintenance_id: this.getCurrentId,
            reported_issues: this.getCorrective.reported_issues,
            is_driver_accountable: this.getCorrective.is_driver_accountable === 'true',
            driver_accountable_value: parseInt(this.getCorrective.driver_accountable_value),
            car_receiver: this.getCorrective.car_receiver,
            labor_cost: parseInt(this.getCorrective.labor_cost),
            parts_cost: parseInt(this.getCorrective.parts_cost),
            estimate_cost_sent_date: this.getCorrective.estimate_cost_sent_date,
            finish_estimate_date: this.getCorrective.finish_estimate_date,
          }
        }
        if(this.getStatusStateSelected === 'READY_FOR_PICKUP') {
          mutateFile = READY_FOR_PICKUP;
          mutateVariables = {
            maintenance_id: this.getCurrentId,
            finish_date: this.getCorrective.finish_date,
            is_driver_accountable: this.getCorrective.is_driver_accountable === 'true',
            driver_accountable_value: parseInt(this.getCorrective.driver_accountable_value),
            car_receiver: this.getCorrective.car_receiver,
          }
        }
        if(this.getStatusStateSelected === 'DONE'){
          mutateFile = DONE;
          mutateVariables = {
            maintenance_id: this.getCurrentId,
            delivery_date: this.getCorrective.delivery_date
          }
        }
        if(this.getStatusStateSelected === 'CANCELED'){
          mutateFile = CANCEL;
          mutateVariables = {
            maintenance_id: this.getCurrentId
          }
        }
        makeMutation(
          mutateFile,
          {
            input: mutateVariables
          }
        ).then(() => {
          return this._refreshList()
        }).catch(() => {
          this.$swal(errorMutation);
        });
      },
      _refreshList() {
        this.$parent.$parent.$children[0].$children[3].$refs.correctiveDataTable.$_getData(); //corrective-table
        this.$parent.$parent.$children[0].$children[2].$apollo.queries.Maintenance.refetch(); //status-tabs
      },
      _selectNewStatus(value) {
        this.setStatusState(value);
        this.disableOkButton = false;
        this.enableExitAlert = true;
        switch(this.getStatusStateSelected) {
          case 'READY_FOR_PICKUP':
            this.modalTitle = this.$t('corrective.modals.readyForPickup_modalTitle');
            this.confirmAlert = this.$t('corrective.modals.readyForPickup_confirmAlert');
            break;
          case 'SCHEDULED':
            this.modalTitle = this.$t('corrective.modals.scheduled_modalTitle');
            this.confirmAlert = this.$t('corrective.modals.scheduled_confirmAlert');
            break;
          case 'CANCELED':
            this.modalTitle = this.$t('corrective.modals.canceled_modalTitle');
            this.confirmAlert = this.$t('corrective.modals.canceled_confirmAlert');
            break;
          case 'PENDING_BUDGET':
            this.modalTitle = this.$t('corrective.modals.pendingBudget_modalTitle');
            this.confirmAlert = this.$t('corrective.modals.pendingBudget_confirmAlert');
            break;
          case 'IN_REPAIR':
            this.modalTitle = this.$t('corrective.modals.inRepair_modalTitle');
            this.confirmAlert = this.$t('corrective.modals.inRepair_confirmAlert');
            break;
          case 'DONE':
            this.modalTitle = this.$t('corrective.modals.done_modalTitle');
            this.confirmAlert = this.$t('corrective.modals.done_confirmAlert');
            break;
          default:
            this.modalTitle = this.$t('corrective.modals.default_modalTitle');
            this.confirmAlert = this.$t('corrective.modals.default_confirmAlert');
        }
      },
      _validateForm() {
        if(this.getModalState === 'INITIAL' && this.getStatusStateSelected === 'PENDING_BUDGET') {
          this.ObjectrCorrectiveValidation.car = Boolean(!this.getCorrective.car);
          this.ObjectrCorrectiveValidation.origin = Boolean(!this.getCorrective.origin);
          this.ObjectrCorrectiveValidation.reported_issues = Boolean(this.getCorrective.reported_issues.length === 0);
          this.ObjectrCorrectiveValidation.garage = Boolean(!this.getCorrective.garage);
          this.ObjectrCorrectiveValidation.garage_arrival_date = Boolean(!this.getCorrective.garage_arrival_date);
          this.ObjectrCorrectiveValidation.is_driver_accountable = Boolean(!this.getCorrective.is_driver_accountable);
          this.ObjectrCorrectiveValidation.driver_accountable_value = Boolean(!this.getCorrective.driver_accountable_value);
          this.ObjectrCorrectiveValidation.car_receiver = Boolean(!this.getCorrective.car_receiver);
          if(
            !this.ObjectrCorrectiveValidation.car
            && !this.ObjectrCorrectiveValidation.origin
            && !this.ObjectrCorrectiveValidation.reported_issues
            && !this.ObjectrCorrectiveValidation.garage
            && !this.ObjectrCorrectiveValidation.garage_arrival_date
            && !this.ObjectrCorrectiveValidation.is_driver_accountable
            && !this.ObjectrCorrectiveValidation.driver_accountable_value
            && !this.ObjectrCorrectiveValidation.car_receiver
          ) {
            this._sa_confirm();
          }
        }
        if(this.getModalState === 'SCHEDULED' && this.getStatusStateSelected === 'PENDING_BUDGET') {
          this.ObjectrCorrectiveValidation.garage_arrival_date = Boolean(!this.getCorrective.garage_arrival_date);
          this.ObjectrCorrectiveValidation.reported_issues = Boolean(!this.getCorrective.reported_issues);
          this.ObjectrCorrectiveValidation.is_driver_accountable = Boolean(!this.getCorrective.is_driver_accountable);
          this.ObjectrCorrectiveValidation.driver_accountable_value = Boolean(!this.getCorrective.driver_accountable_value);
          this.ObjectrCorrectiveValidation.car_receiver = Boolean(!this.getCorrective.car_receiver);
          if(
            !this.ObjectrCorrectiveValidation.garage_arrival_date
            && !this.ObjectrCorrectiveValidation.reported_issues
            && !this.ObjectrCorrectiveValidation.is_driver_accountable
            && !this.ObjectrCorrectiveValidation.driver_accountable_value
            && !this.ObjectrCorrectiveValidation.car_receiver
          ) {
            this._sa_confirm();
          }
        }
        if(this.getStatusStateSelected === 'SCHEDULED') {
          this.ObjectrCorrectiveValidation.car = Boolean(!this.getCorrective.car);
          this.ObjectrCorrectiveValidation.origin = Boolean(!this.getCorrective.origin);
          this.ObjectrCorrectiveValidation.reported_issues = Boolean(this.getCorrective.reported_issues.length === 0);
          this.ObjectrCorrectiveValidation.garage = Boolean(!this.getCorrective.garage);
          this.ObjectrCorrectiveValidation.schedule_date = Boolean(!this.getCorrective.schedule_date);
          if(
            !this.ObjectrCorrectiveValidation.car
            && !this.ObjectrCorrectiveValidation.origin
            && !this.ObjectrCorrectiveValidation.reported_issues
            && !this.ObjectrCorrectiveValidation.garage
            && !this.ObjectrCorrectiveValidation.schedule_date
          ) {
            this._sa_confirm();
          }
        }
        if(this.getStatusStateSelected === 'IN_REPAIR') {
          this.ObjectrCorrectiveValidation.estimate_cost_sent_date = Boolean(!this.getCorrective.estimate_cost_sent_date);
          this.ObjectrCorrectiveValidation.driver_accountable_value = Boolean(!this.getCorrective.driver_accountable_value);
          this.ObjectrCorrectiveValidation.labor_cost = Boolean(!this.getCorrective.labor_cost);
          this.ObjectrCorrectiveValidation.parts_cost = Boolean(!this.getCorrective.parts_cost);
          this.ObjectrCorrectiveValidation.finish_estimate_date = Boolean(!this.getCorrective.finish_estimate_date);
          this.ObjectrCorrectiveValidation.reported_issues = Boolean(this.getCorrective.reported_issues.length === 0);
          this.ObjectrCorrectiveValidation.is_driver_accountable = Boolean(!this.getCorrective.is_driver_accountable);
          this.ObjectrCorrectiveValidation.car_receiver = Boolean(!this.getCorrective.car_receiver);
          if(
            !this.ObjectrCorrectiveValidation.estimate_cost_sent_date
            && !this.ObjectrCorrectiveValidation.driver_accountable_value
            && !this.ObjectrCorrectiveValidation.labor_cost
            && !this.ObjectrCorrectiveValidation.parts_cost
            && !this.ObjectrCorrectiveValidation.finish_estimate_date
            && !this.ObjectrCorrectiveValidation.reported_issues
            && !this.ObjectrCorrectiveValidation.is_driver_accountable
            && !this.ObjectrCorrectiveValidation.car_receiver
          ) {
            this._sa_confirm();
          }
        }
        if(this.getStatusStateSelected === 'READY_FOR_PICKUP') {
          this.ObjectrCorrectiveValidation.finish_date = Boolean(!this.getCorrective.finish_date);
          this.ObjectrCorrectiveValidation.is_driver_accountable = Boolean(!this.getCorrective.is_driver_accountable);
          this.ObjectrCorrectiveValidation.driver_accountable_value = Boolean(!this.getCorrective.driver_accountable_value);
          this.ObjectrCorrectiveValidation.car_receiver = Boolean(!this.getCorrective.car_receiver);
          if(
            !this.ObjectrCorrectiveValidation.finish_date
            && !this.ObjectrCorrectiveValidation.is_driver_accountable
            && !this.ObjectrCorrectiveValidation.driver_accountable_value
            && !this.ObjectrCorrectiveValidation.car_receiver
          ) {
            this._sa_confirm();
          }
        }
        if(this.getStatusStateSelected === 'DONE') {
          this.ObjectrCorrectiveValidation.delivery_date = Boolean(!this.getCorrective.delivery_date);
          if(
            !this.ObjectrCorrectiveValidation.delivery_date
          ) {
            this._sa_confirm();
          }
        }
        if(this.getStatusStateSelected === 'CANCELED') {
          this._sa_confirm();
        }
      },
      __loadData() {
        if (this.getModalState === 'INITIAL') {
          return false;
        }

        const corrective = this.getCorrective;
        const inputObject = {
          ...corrective,
          car: corrective.car.id,
          garage: corrective.garage.id,
          is_driver_accountable: corrective.is_driver_accountable === null ? corrective.is_driver_accountable : corrective.is_driver_accountable.toString(),
          last_driver: {
            ...corrective.last_driver
          },
          driver_accountable_value: corrective.driver_accountable_value && corrective.driver_accountable_value.toString(),
          labor_cost: corrective.labor_cost && corrective.labor_cost.toString(),
          parts_cost: corrective.parts_cost && corrective.parts_cost.toString(),
        };
        if (corrective.schedule) {
          inputObject.schedule_date = corrective.schedule.scheduled_at;
        }
        this.ObjectrCorrective = inputObject;
      },
      __sendMutation(bvModalEvt) {
        bvModalEvt.preventDefault();
        this.setCorrective(this.ObjectrCorrective);
        this._validateForm()
      },
      _sa_confirm() {
        this.$swal({
          type: 'warning',
          title: this.confirmAlert,
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim'
        }).then((result) => {
          if(result.value) {
            this._makeMutationsCase();
            this.__closeModal();
          }
        });
      },
      _sa_cancel(bvModalEvt) {
        if(this.enableExitAlert) {
          bvModalEvt.preventDefault();
          this.$swal({
            type: 'warning',
            title: this.cancelAlert,
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim'
          }).then((result) => {
            if(result.value) {
              this.__closeModal();
            }
          });
        } else {
          this.__closeModal()
        }
      },
      __clearModal() {
        this.setCorrective({});
        this.setModalState('INITIAL');
        this.setStatusState('INITIAL');
        this.ObjectrCorrective = {
          car: '',
          origin: '',
          status: null,
          reported_issues: [],
          garage: '',
          garage_arrival_date: '',
          driver: '',
          schedule_date: '',
          delivery_date: '',
          estimate_cost_sent_date: '',
          finish_estimate_date: '',
          finish_date: '',
          is_driver_accountable: '',
          driver_accountable_value: '',
          car_receiver: '',
          labor_cost: '',
          parts_cost: '',
          last_driver: {
            name: ''
          }
        };
        this.ObjectrCorrectiveValidation = {
          car: false,
          origin: false,
          status: false,
          reported_issues: false,
          garage: false,
          garage_arrival_date: false,
          driver: false,
          schedule_date: false,
          delivery_date: false,
          estimate_cost_sent_date: false,
          finish_estimate_date: false,
          finish_date: false,
          is_driver_accountable: false,
          driver_accountable_value: false,
          car_receiver: false,
          labor_cost: false,
          parts_cost: false,
          last_driver: {
            name: false
          }
        };
      },
      __closeModal() {
        this.$root.$emit('bv::hide::modal', 'modalCorrective');
        this.__clearModal();
        this.enableExitAlert = false;
        this.disableOkButton = true;
      }
    }
  };
</script>

<style lang="sass">
.group-disabled
  opacity: .65
.empty-warning
  border-color: #ff0000
  background-color: rgba(255,0,0,.1)
.empty-warning
  input[type="text"]
    border-color: #ff0000
    background-color: rgba(255,0,0,.1)
.problems-list
  column-count: 6
  .custom-checkbox.custom-control-inline
    width: 13.5%
    .custom-control-label
      font-size: 12px
.divider
  height: 1px;
  margin: 10px 0;
  padding-bottom: 10px;
  border-top: 1px solid #5c636b;
</style>
