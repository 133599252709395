var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("custom-data-table", {
        ref: "correctiveDataTable",
        attrs: {
          "query-context": "Maintenance",
          "query-node": "correctives",
          "query-interface": 2,
          fields: _vm.fields,
          filters: _vm.filters,
          "fixed-filters": _vm.fixed_filters,
          query: _vm.corrective_list_query,
        },
        on: { "vuetable:row-clicked": _vm._openCorrectiveModal },
        scopedSlots: _vm._u([
          {
            key: "license_plate",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(_vm._s(props.rowData.license_plate)),
              ])
            },
          },
          {
            key: "status",
            fn: function (props) {
              return _c(
                "span",
                {},
                [
                  _c(
                    "b-badge",
                    {
                      attrs: {
                        variant: _vm._f("status_variant")(props.rowData.status),
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm
                              .$t("corrective.status." + props.rowData.status)
                              .toUpperCase()
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              )
            },
          },
          {
            key: "scheduled_at",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("moment")(props.rowData.scheduled_at, "DD/MM/YYYY")
                    ) +
                    " "
                ),
              ])
            },
          },
          {
            key: "days_from_garage_arrival_date",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(_vm._s(props.rowData.days_from_garage_arrival_date)),
              ])
            },
          },
          {
            key: "garageName",
            fn: function (props) {
              return _c("span", {}, [_vm._v(_vm._s(props.rowData.garageName))])
            },
          },
          {
            key: "is_driver_accountable",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      `corrective.dataTableFields.driverAccountable.${props.rowData.is_driver_accountable}`
                    )
                  )
                ),
              ])
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }