import { i18next } from '@translate/i18n'

export const ORIGIN_LIST = [{
    text: i18next.t('corrective.enums.origin_RETURN'),
    value: 'RETURN'
  },
  {
    text: i18next.t('corrective.enums.origin_WARRANTY'),
    value: 'WARRANTY'
  },
  {
    text: i18next.t('corrective.enums.origin_MECHANICAL_ISSUES'),
    value: 'MECHANICAL_ISSUES'
  },
  {
    text: i18next.t('corrective.enums.origin_CRASH'),
    value: 'CRASH'
  }
];

export const PROBLEMS_LIST = [
  {
    value: 'eletric',
    text: i18next.t('corrective.enums.problems_eletric')
  },
  {
    value: 'cooling',
    text: i18next.t('corrective.enums.problems_cooling')
  },
  {
    value: 'direction',
    text: i18next.t('corrective.enums.problems_direction')
  },
  {
    value: 'body_shop',
    text: i18next.t('corrective.enums.problems_body_shop')
  },
  {
    value: 'accessories',
    text: i18next.t('corrective.enums.problems_accessories')
  },
  {
    value: 'block',
    text: i18next.t('corrective.enums.problems_block')
  },
  {
    value: 'engine',
    text: i18next.t('corrective.enums.problems_engine')
  },
  {
    value: 'lights',
    text: i18next.t('corrective.enums.problems_lights')
  },
  {
    value: 'glasses',
    text: i18next.t('corrective.enums.problems_glasses')
  },
  {
    value: 'brakes',
    text: i18next.t('corrective.enums.problems_brakes')
  },
  {
    value: 'ignition',
    text: i18next.t('corrective.enums.problems_ignition')
  },
  {
    value: 'feed',
    text: i18next.t('corrective.enums.problems_feed')
  },
  {
    value: 'suspension',
    text: i18next.t('corrective.enums.problems_suspension')
  },
  {
    value: 'transmission',
    text: i18next.t('corrective.enums.problems_transmission')
  },
  {
    value: 'heavy',
    text: i18next.t('corrective.enums.problems_heavy')
  },
  {
    value: 'damage_light',
    text: i18next.t('corrective.enums.problems_damage_light')
  },
  {
    value: 'damage_medium',
    text: i18next.t('corrective.enums.problems_damage_medium')
  },
  {
    value: 'damage_big',
    text: i18next.t('corrective.enums.problems_damage_big')
  }
];

export const STATUS_LIST = [
  'SCHEDULED',
  'PENDING_BUDGET',
  'IN_REPAIR',
  'READY_FOR_PICKUP',
  'DONE',
  'CANCELED'
];
