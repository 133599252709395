var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c("div", { staticClass: "row mb-5" }, [
        _c("div", { staticClass: "col" }, [
          _c("h2", [
            _vm._v(_vm._s(_vm.$t("corrective.titles.maintenanceView"))),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "col" },
          [
            _c(
              "b-button",
              {
                staticClass: "float-right",
                attrs: { variant: "primary" },
                on: { click: _vm._openCorrectiveModal },
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("corrective.buttons.newSchedule")) + " "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("status-tabs"),
      _c(
        "div",
        { staticClass: "row" },
        [_c("corrective-maintenance-table", { staticClass: "col" })],
        1
      ),
      _c("modal-corrective"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }