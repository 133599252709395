<template>
  <b-card-group
    v-if="tabs"
    class="mb-5"
  >
    <card-widget
      v-for="(value, name, index) in tabs"
      :id="name"
      :key="index"
      :to="makeRoute(name)"
      :title="$t(`corrective.tabs.${name}`)"
      :icon="activeTabs[name]"
      :value="String(value.total)"
      :active="tabActive(name)"
    />
  </b-card-group>
</template>

<script>
import CardWidget from '@components/shared/card-widget';
import CORRECTIVE_TOTALS from '@graphql/corrective/queries/totals.gql'

export default {
  components: {
    CardWidget,
  },
  data() {
    return {
      activeTabs: { // set active tabs and assign icon class
        all: "fa fa-car",
        in_repair: "fa fa-wrench",
        pending_budget: "fa fa-dollar",
        ready_for_pickup: "fa fa-check-square",
        scheduled: "fa fa-calendar"
      },
      Maintenance: {}
    }
  },
  computed: {
    tabs() {
      let raw = this.Maintenance
      let tabs = Object.keys(this.activeTabs)
      if (!raw) return
      const filtered = Object.keys(raw)
        .filter(key => tabs.includes(key))
        .reduce((obj, key) => {
          obj[key] = raw[key];
          return obj;
        }, {});

      return filtered
    }
  },
  apollo: {
    Maintenance: {
      query: CORRECTIVE_TOTALS,
      fetchPolicy: 'no-cache'
    },
  },
  methods: {
    makeRoute: (name) => {
      if (name !== 'all') return `/maintenance/corrective/${name}`
      else return '/maintenance/corrective/'
    },
    tabActive(name) {
      let params = this.$route.params;
      if (params.carStatus) return params.carStatus === name
      else if (name === "all" && !params.carStatis) return true
      else return false
    }
  }
}
</script>
