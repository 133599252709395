<template>
  <div>
    <custom-data-table
      ref="correctiveDataTable"
      query-context="Maintenance"
      query-node="correctives"
      :query-interface="2"
      :fields="fields"
      :filters="filters"
      :fixed-filters="fixed_filters"
      :query="corrective_list_query"
      @vuetable:row-clicked="_openCorrectiveModal"
    >
      <span slot="license_plate" slot-scope="props">{{ props.rowData.license_plate }}</span>
      <span slot="status" slot-scope="props">
        <b-badge :variant="props.rowData.status | status_variant">
          {{ $t('corrective.status.' + props.rowData.status).toUpperCase() }}
        </b-badge>
      </span>
      <span slot="scheduled_at" slot-scope="props"> {{ props.rowData.scheduled_at | moment('DD/MM/YYYY') }} </span>
      <span slot="days_from_garage_arrival_date" slot-scope="props">{{ props.rowData.days_from_garage_arrival_date }}</span>
      <span slot="garageName" slot-scope="props">{{ props.rowData.garageName }}</span>
      <span slot="is_driver_accountable" slot-scope="props">{{ $t(`corrective.dataTableFields.driverAccountable.${props.rowData.is_driver_accountable}`) }}</span>
    </custom-data-table>
  </div>
</template>

<script>
  import { makeQuery } from "@graphql/middleware";
  import { mapGetters, mapActions } from 'vuex';
  import CustomDataTable from '@components/CustomDataTable';
  import CORRECTIVE_LIST from '@graphql/corrective/queries/list.gql';
  import CORRECTIVE_GET from '@graphql/corrective/queries/get.gql';

  export default {
    components: {
      CustomDataTable
    },
    data() {
      return {
        corrective_list_query: CORRECTIVE_LIST,
        fieldsDefault: [
          {
            name: 'license_plate',
            title: this.$t('corrective.dataTableFields.license_plate'),
            sortField: 'license_plate'
          },
          {
            name: 'status',
            title: this.$t('corrective.dataTableFields.status'),
            sortField: "status"
          },
          {
            name: 'scheduled_at',
            title: this.$t('corrective.dataTableFields.garage_arrival_date'),
            sortField: 'garage_arrival_date'
          },
          {
            name: 'garage.name',
            title: this.$t('corrective.dataTableFields.garage')
          },
          {
            name: 'is_driver_accountable',
            title: this.$t('corrective.dataTableFields.is_driver_accountable'),
          }
        ],
        fieldsRepair: [
          {
            name: 'days_from_garage_arrival_date',
            title: this.$t('corrective.dataTableFields.days_from_garage_arrival_date'),
            sortField: 'days_from_garage_arrival_date'
          }
        ]
      }
    },
    computed: {
      ...mapGetters({
        getModalState: 'corrective/g_modalState',
        getStatusState: 'corrective/g_statusState'
      }),
      fixed_filters() {
        let carStatus = this.$route.params.carStatus || ""
        if (carStatus) return { status: carStatus.toUpperCase() }
        else return null
      },
      fields () {
        let carStatus = this.$route.params.carStatus || ""
        if (carStatus && carStatus == "in_repair") {
          return [
            ...this.fieldsDefault,
            ...this.fieldsRepair
          ]
        } else {
          return this.fieldsDefault
        }

      },
      filters() {
        return {
          search: {
            type: 'textfield',
            props: {
              label: this.$t('corrective.labels.search'),
              placeholder: this.$t('corrective.labels.searchPlaceholder'),
              cols: 6,
            },
            graphqlQuery(value) {
              return {
                license_plate: value
              };
            },
          },
          schedule_date: {
            type: 'datepicker',
            props: {
              label: this.$t('corrective.labels.datepicker'),
              range: false,
              format: 'DD/MM/YYYY',
              confirm: false,
              width: 'auto',
            },
            valueParserType: String,
            valueParserFunction: date => {
              return this.$moment(date);
            },
            graphqlQuery(date) {
              if (date.isValid())
                return {
                  scheduled_at: date.format('YYYY-MM-DD')
                };
            },
          }
        };
      },
    },
    methods: {
      ...mapActions({
        setModalState: 'corrective/a_modalState',
        setStatusState: 'corrective/a_statusState',
        setCurrentId: 'corrective/a_currentId',
        setCorrective: 'corrective/a_corrective',
      }),
      async _openCorrectiveModal(data) {
        const result = await makeQuery(
          CORRECTIVE_GET,
          { maintenance_id: data.data.maintenance_id }
        );
        const maintenance = result.data.Maintenance.corrective;
        this.setCorrective(maintenance);
        this.setCurrentId(maintenance.maintenance_id);
        this.setModalState(maintenance.status);
        // this.setStatusState(maintenance.status);
        this.$root.$emit('bv::show::modal', 'modalCorrective');
      }
    },
  };

</script>
