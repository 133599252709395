<template>
  <Layout>
    <div class="row mb-5">
      <div class="col">
        <h2>{{ $t('corrective.titles.maintenanceView') }}</h2>
      </div>
      <div class="col">
        <b-button variant="primary" class="float-right" @click="_openCorrectiveModal">
          {{ $t('corrective.buttons.newSchedule') }}
        </b-button>
      </div>
    </div>
    <status-tabs />
    <div class="row">
      <corrective-maintenance-table
        class="col"
      />
    </div>
    <modal-corrective />
  </Layout>
</template>

<script>
  import { mapActions } from 'vuex';
  import Layout from '@layouts/main';
  import StatusTabs from '@components/shared/status-tabs';
  import CorrectiveMaintenanceTable from '@components/maintenance/corrective-maintenance-table';
  import ModalCorrective from '@components/maintenance/modal/corrective';

  export default {
    name: 'CorrectiveMaintenance',
    components: {
      CorrectiveMaintenanceTable,
      Layout,
      StatusTabs,
      ModalCorrective
    },
    methods: {
      ...mapActions({
        setModalState: 'corrective/a_modalState',
        setStatusState: 'corrective/a_statusState'
      }),
      _openCorrectiveModal() {
        this.setModalState('INITIAL');
        this.setStatusState('INITIAL');
        this.$root.$emit('bv::show::modal', 'modalCorrective');
      }
    }
  };
</script>
